@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(https://fonts.googleapis.com/css?family=Black+Han+Sans&display=swap);
@font-face {
  font-family: RixYeoljeongdoRegular;
  src: url(/static/media/RixYeoljeongdoRegular.ac06b20f.woff) format("woff"),
    url(/static/media/RixYeoljeongdoRegular.f88013e2.ttf) format("truetype");
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

* {
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", sans-serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Black Han Sans", "Spoqa Han Sans", "Spoqa Han Sans JP",
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

